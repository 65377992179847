.navBarContainer {
    display: flex;
    flex-wrap: wrap;
    color: white;
    align-items: center;
    padding: 16px 72px;
    background-color: #0f1942;
    background-image: url(https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/menu_bg.svg);
    background-repeat: no-repeat;
    background-position: 100%;
  }

.logoText {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    align-items: center;
  }

.navBarLogo {
    display: flex;
    flex: 1;
    align-items: center;
  }

.navBarConnect {
    display: flex;
    flex: 1;
    justify-content: right;
  }

.menuContainer {
    display: flex;
    width: 50%;
    flex: 1,
  }

.menuButton {
    display: flex;
    font-size: 18px;
    cursor: pointer;
    padding-left: 50px;
  }

.avaxbalContainer {
    display: flex;
    align-items: center;
    height: 54px;
    border: 2px solid #0d69f3;
    margin-right: 20px;
    border-radius: 5px;
    padding: 10px 10px;
  }

.avaxbalance {
    margin-left: 10;
    font-size: 20;
    color: white;
  }

.right {
    display: flex;
    flex-direction: row;
  }

.logoCont {
  display: flex;
  flex: 1;
  flex-direction: row;
}
