.topAddresses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
  white-space: nowrap;
  gap: 12px;
  margin: 48px 0 36px 0;
}

.tableCellPointer {
  cursor: pointer;
}
