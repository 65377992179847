.text {
  border-radius: 5px;
  border: 1px;
  border-color: #0f1942;
  padding: 5px;
}

.box {
  position: absolute;
  width: 80%;
  max-width: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0f1942;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 64px;
}

.image {
  display: flex;
  width: 150px;
  height: 150px;
  border: 2px solid #0d69f3;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.vDivider {
  border-left: 2px solid white;
  height: 98%;
  width: 0px;
}

.topDiv {
  width: 45%;
}

.rightGrid {
  display: flex;
  flex-direction: row;
}

.rightContainer {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.rightBox {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.ID {
  width: 50%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.wallet {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.topRight {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-left: 5px;
}

.bottomRight {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  gap: 150px;
}

.Stats {
  height: 100%;
  padding: 10px 10px 10px 0px;
  display: flex;
  flex-direction: column;
}

.Stat {
  padding: 5px;
}

.buttonDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding-left: 20px;
}

.logout {
  width: 125px;
}

.profileImage {
  width: 147px;
  height: 147px;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
}

.walletAddress {
  padding-left: 5px;
}

.before {
  font-weight: bold;
}

.transactionsAnchor {
  color: #0d69f3;
  margin-top: 8px;
  text-decoration: underline;
  cursor: pointer;
}
