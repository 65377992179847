.coin {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 1s ease-in;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.coin.flip-heads {
  animation: flipHeads 3s ease-out forwards;
}

.coin.flip-tails {
  animation: flipTails 3s ease-out forwards;
}

.side-heads {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-image: url("https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/heads.png");
  background-size: contain;
}

.side-tails {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-image: url("https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/tails.png");
  background-size: contain;
}

@keyframes flipHeads {
  from { transform: rotateY(0); }
  to { transform: rotateY(1800deg); }
}

@keyframes flipTails {
  from { transform: rotateY(0); }
  to { transform: rotateY(1980deg); }
}
