* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

html {
  min-height: 100%;
  background-image: url('https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/homeBg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #091233;
}
body {
  background-color: transparent;
  color: white;
}

.App {
  position: relative;
  z-index: 1;
  transition: all 300ms ease-in-out;
}
.App:hover {
  transform: scale(1.05);
}

/* .App::before{
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background: white;
  transition: all 300ms ease-in-out;
  transform: scaleX(0);
}
.App:hover::before {
  transform: scaleX(1);
} */

.join-btn {
  background: linear-gradient(278.65deg, #ee6b22 43.4%, #e8c823);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.join-btn:hover {
  background: linear-gradient(278.65deg, #e8c823 43.4%, #ee6b22);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.wait-btn {
  background: linear-gradient(278.65deg, #17b217 43.4%, #038d33);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.wait-btn:hover {
  background: linear-gradient(278.65deg, #8a0000 43.4%, #dc0000);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.cancel-btn {
  width: 80px;
  background: linear-gradient(278.65deg, #dc0000 43.4%, #8a0000);
  color: #fff;
  border: none;
  padding: 7px 0;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.cancel-btn:hover {
  background: linear-gradient(278.65deg, #8a0000 43.4%, #dc0000);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.stats-btn {
  background: linear-gradient(278.65deg, #0f3e8e 43.4%, #0d6efc);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.stats-btn:hover {
  background: linear-gradient(278.65deg, #0d6efc 43.4%, #0f3e8e);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.navbar {
  display: flex;
  flex: 1;
  padding-bottom: 5px;
}

.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 0px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.navbar a:hover {
  color: blue;
}

.navbar p {
  padding-right: 15px;
}

.claim-button {
  border-radius: 5px;
  background: green;
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.claim-button:disabled {
  border-radius: 5px;
  background: rgb(58, 110, 58);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.looser-button {
  border-radius: 5px;
  background: #dc0000;
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.looser-button:disabled {
  border-radius: 5px;
  background: rgb(110, 58, 58);
  color: #fff;
  border: none;
  padding: 7px 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
